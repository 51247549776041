<script>
import api from "@/api";

export default {
  name: "SearchSlotResults",
  props: ["logout"],
  data: function () {
    return {
      results: [],
      date_: null,
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
    f_date() {
      if (this.date_) {
        return this.date_;
      }
      return this.$route.query.rd;
    },
  },
  methods: {
    get24HourTime(parts) {
      var time = parts[0];
      if (parts[1].toLowerCase() === "pm") {
        var pp = time.split(":");
        var p1 = parseInt(pp[0]);
        if (p1 < 12) {
          p1 += 12;
        }
        time = `${p1}:${pp[1]}`;
      }
      return time;
    },
    makeBooking(slot) {
      var d = this.query;
      var startParts = slot.start.split(" ");
      var startTime = this.get24HourTime(startParts);
      var endParts = slot.end.split(" ");
      var endTime = this.get24HourTime(endParts);

      var paramData = {
        start_time_digits: startTime,
        end_time_digits: endTime,
        rehearsal_date: d.rd,
        space: slot.space,
        slug: slot.space.slug,
      };
      this.$router.push({
        name: "Booking",
        query: paramData,
        params: {
          slug: slot.space.slug,
        },
      });
    },
    getSearchSlotResults() {
      var url = "/searchSlot?";
      this.$store.dispatch("General/updateLoading", true);
      api.Util.axios
        .get(url, { params: this.query })
        .then((response) => {
          this.results.splice(0);
          this.results = response.data.slots;
          this.date_ = response.data.date;
          this.$store.dispatch("General/updateLoading", false);
        })
        .catch((err) => {
          this.$store.dispatch("General/updateLoading", false);
          console.error(err);
        });
    },
    isNonRepetitiveName: function (space) {
      var name1 = space.name;
      var name2 = "";
      if (space.space_company !== undefined) {
        name2 = space.space_company.company_name;
      }
      if (name1 === name2) {
        return false;
      }
      return true;
    },
  },
  watch: {
    $route() {
      this.getSearchSlotResults();
    },
  },
  created: function () {
    return this.getSearchSlotResults();
  },
};
</script>

<template>
  <div id="SearchSlotResults">
    <div class="container">
      <h4>Date: {{ f_date }}</h4>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Space</th>
              <th>Hourly Rate</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Book</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="results.length">
              <tr v-for="slot in results" :key="slot.space.id">
                <td>
                  <router-link
                    :to="{
                      name: 'Space-Sections',
                      params: { slug: slot.space.slug },
                    }"
                  >
                    {{ slot.space.space_company.company_name }}
                    <template v-if="isNonRepetitiveName(slot.space)">
                      - {{ slot.space.name }}
                    </template>
                  </router-link>
                </td>
                <td>Ksh. {{ slot.space.sl_product_obj.hourly_rate }} /=</td>
                <td>{{ slot.start }}</td>
                <td>{{ slot.end }}</td>
                <td>
                  <a
                    href="#"
                    @click.prevent="makeBooking(slot)"
                    class="btn btn-success"
                  >
                    Book Now
                  </a>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="text-center" colspan="5">No Data</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#SearchSlotResults {
  padding-top: 10px;
  .container {
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    border: 1px solid #ccc;
  }
}
.table {
  background: white;
}
</style>
